import { RiRulerLine, RiScales2Line, RiTimeLine } from 'react-icons/ri';
import karl from './assets/karl.webp';

const birthdate = new Date(2022, 3, 28);
const weight = 3530;
const lenght = 53;
const name = 'Karl';
const time = '21:45';

export default function App() {
  return (
    <div className="min-h-screen w-screen flex items-center justify-center bg-background px-3 py-2">
      <div className="bg-white shadow-lg sm:flex">
        <div>
          <img
            width={600}
            height={900}
            className="w-full sm:w-[300px]"
            src={karl}
            alt={`${name} at birth`}
          />
        </div>
        <div className="p-4 sm:w-[450px] flex flex-col justify-between">
          <div>
            <h1 className="text-violet text-3xl text-center">Hello World!</h1>
            <h2 className="text-violet text-xl text-center my-3">
              Mein Name ist {name}.
            </h2>
            <p className="mt-2 text-lg text-violet">
              Ich bin ein Open-Source Projekt von Johanna & Daniel. Nach
              erfolgreicher, neunmonatiger Entwicklungsphase erblickte ich am{' '}
              {birthdate.toLocaleDateString()} das Licht der Welt.
            </p>
            <p className="mt-1 text-lg text-violet">
              Mama und Papa sind überglücklich und danken euch für die vielen
              Wünsche, die sie vor dem Release erreicht haben.
            </p>
          </div>
          <div>
            <hr className="my-2 border-violet border-1 rounded-md" />
            <div className="text-violet flex flex-col gap-2">
              <div className="sm:flex justify-between">
                <div className="flex gap-2">
                  <RiTimeLine className=" text-2xl" />
                  <span>{time} Uhr</span>
                </div>
                <div className="flex gap-2">
                  <RiScales2Line className=" text-2xl" />
                  <span>{weight} Gramm</span>
                </div>
                <div className="flex gap-2">
                  <RiRulerLine className="text-2xl" />
                  <span>{lenght} Zentimeter</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
